'use strict';

import {NewsTicker} from "./entry";

declare const $: any;

declare global {
	interface Window {
		fullpage: any;
	}
}

var $window = $(window);
var $header = $('.global-header');

$(() => {
	$header.addClass('is-small');

	// console.log(window.fullpage);
	// alert(`fullpage ${$window.width()}, ${$window.height()}`);

	var fullpage = new window.fullpage('.full-page', {
		licenseKey          : 'A9B5BE51-132B43F7-BB32C64D-814DFCD5',
		sectionSelector     : '.scene',
		fitToSection        : false,
		//recordHistory   : true,
		scrollOverflow      : true,
		verticalCentered    : false,
		// navigation      : true,
		animateAnchor       : false,
		scrollingSpeed      : 1200,
		normalScrollElements: '.off-canvas',
		onLeave             : function(origin, destination, direction) {
			// console.log(origin, destination, direction);
			if ( $('html').hasClass('open-off-canvas') ) {
				return false;
			}

			if ( destination.index >= 2 ) {
				$header.addClass('is-show');
			} else {
				$header.removeClass('is-show is-small');
			}

			if ( destination.index >= 3 ) {
				$header.addClass('is-small');
			}
		},
		afterLoad           : function(origin, destination, direction) {
			//console.log(origin, destination, direction);
		},
		afterRender         : function() {
			// $('#container').removeClass('is-hide');
		}
	});

	var newsTicker = new NewsTicker({$target: $('.ticker')});
	newsTicker.init();

	var $homeHeroImage = $('#home .hero-mask svg .image');
	var $parent        = $('#home .hero-mask');

	var imageBounding = $homeHeroImage[0].getBoundingClientRect();
	var imageWidth    = imageBounding.width;
	var imageHeight   = imageBounding.height;
	var imageRatio    = imageWidth / imageHeight;

	var parentWidth, parentHeight, _parentWidth, _parentHeight;
	(function loop() {
		parentWidth  = $parent.width() + 2;
		parentHeight = $parent.height() + 2;

		if ( parentWidth !== _parentWidth || parentHeight !== _parentHeight ) {

			var windowRatio = parentWidth / parentHeight;

			// console.log(parentWidth, parentHeight);

			if ( imageRatio > windowRatio ) {
				var scale = parentHeight / imageHeight;
				var x     = -(imageWidth * scale - parentWidth) / 2 / scale;
				$homeHeroImage.attr('transform', 'scale(' + scale + ') translate(' + x + ', 0)');

			} else {
				var scale = parentWidth / imageWidth;
				var y     = -(imageHeight * scale - parentHeight) / 2 / scale;
				$homeHeroImage.attr('transform', 'scale(' + scale + ') translate(0, ' + y + ')');
			}
		}

		_parentWidth  = parentWidth;
		_parentHeight = parentHeight;

		window.requestAnimationFrame(loop);
	})();

});
